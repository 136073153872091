import { extend } from 'vee-validate';
import { required, max } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'Detail keterangan wajib diisi',
});

extend('max', {
  ...max,
  params: ['length'],
  message: 'Maksimal {length} karakter',
});
