<template>
  <bg-modal :value="isOpen" @close="closeModal">
    <bg-text size="heading-4" class="mb-32"> Keterangan Tambahan </bg-text>

    <ValidationObserver ref="observer">
      <ValidationProvider
        name="Detail Keterangan"
        rules="required|max:1500"
        v-slot="{ errors }"
      >
        <bg-field
          label="Detail Keterangan"
          :error="!!errors.length"
          :message="errors[0]"
        >
          <bg-textarea
            v-model="form.notes"
            placeholder="Masukkan Detail Keterangan"
            :max-Char="1500"
            :has-counter="false"
            rows="15"
            data-testid="notes"
          />
        </bg-field>
      </ValidationProvider>
    </ValidationObserver>

    <template #footer>
      <div class="flex align-center justify-end">
        <bg-button class="mr-16" :disabled="loading" @click="closeModal">
          Kembali
        </bg-button>
        <bg-button
          variant="primary"
          :disabled="!form.notes"
          :loading="loading"
          data-testid="simpan-btn"
          @click="handleSave"
        >
          Simpan
        </bg-button>
      </div>
    </template>
  </bg-modal>
</template>

<script>
import { BgTextarea, BgModal, BgButton, BgField, BgText } from 'bangul-vue';
import detailTransferApi from '@admin_endpoints/detail-transfer';

import { ValidationProvider } from 'vee-validate';
import '@admin/pages/DetailTransfer/config/validationRules.js';

export default {
  name: 'ReportAdditionalNotesModal',

  components: {
    BgTextarea,
    BgModal,
    BgButton,
    BgField,
    BgText,
    ValidationProvider,
  },

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    currentNote: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      loading: false,
      form: {
        notes: '',
      },
    };
  },

  computed: {
    approvalId() {
      return this.$route.params.approval_id;
    },
  },

  watch: {
    isOpen: {
      immediate: true,
      handler(isModalOpen) {
        if (isModalOpen && this.currentNote) {
          this.form.notes = this.currentNote;
        }
      },
    },
  },

  methods: {
    closeModal() {
      this.$emit('update:isOpen', false);
      this.form.notes = '';
    },

    async handleSave() {
      const isValid = await this.$refs.observer.validate();

      if (isValid) {
        this.loading = true;

        try {
          await detailTransferApi.updateAdditionalNotes(
            this.approvalId,
            this.form
          );

          this.$emit('action-success');
          this.$toast.show('Detail Keterangan berhasil ditambahkan.');
          this.closeModal();
        } catch (error) {
          console.error(error);
          const errorMessages = error.response?.data.issue?.details || [];

          this.$toast.show(
            errorMessages[0] ||
              'Terjadi kesalahan saat menambahkan Detail Keterangan.'
          );
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>
